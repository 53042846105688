@import "variable.scss";

#runningtext {
  height: 22px;
  width: 100%;

  position: fixed;
  top: 0;
  z-index: 2000;

  color: white;
  background-color: $primary-color;
  // background-color: $secondary-color;
  // border-bottom: 5px solid $primary-color;

  marquee {
    margin-left: 125px;
    margin-right: 175px;
    margin-top: 2px;

    p {
      font-size: .80rem;
      font-family: "IBM Plex Sans",sans-serif;
      // font-weight: 700;
      -webkit-font-smoothing: antialiased;
    }

    a {
      color: white;
      text-decoration: underline;
      font-style: oblique;
    }
  }
}

.running {
  top: 22px;
}

.navbar {
  box-shadow: 0 2px 8px -2px rgba(0,0,0,.2);

  &.navbar-expand-lg {
    padding: 0 !important;
  }

  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: $bg-light;
  // min-height: 70px;
  // border-top: 6px solid $primary-color;
  // border-bottom: 1px solid $border-comp-color;
  min-height: 75px;

  // .dropdown-menu {
  //   background-color: $bg-light;
  //   color: $text-dark-color !important;
  //   border: 0;
  // }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjQgNmgtMjR2LTRoMjR2NHptMCA0aC0yNHY0aDI0di00em0wIDhoLTI0djRoMjR2LTR6Ii8+PC9zdmc+");
  }

  .navbar-brand {
    img {
      height: 35px;
      margin-left: 100px;
    }
  }

  .navbar-nav {
    padding-right: 125px;

    .nav-item {
      cursor: pointer;
      padding: 0px;

      &:hover {
        // background-color: red;
      }

      &.active {
        .nav-link {
          // color: $primary-color !important;
          color: $secondary-color !important;
        }
      }
      .nav-link {
        padding: 25px 13px;
        color: $text-dark-color !important;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.05em;
      }

      .dropdown-menu {
        .dropdown-item {
          &:active {
            background-color: #eee;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .navbar-brand {
    img {
      margin-left: 30px !important;
    }
  }
  .navbar-nav {
    padding-right: 0px !important;

    .nav-item {
      padding: 0px 50px !important;

      .nav-link {
        padding: 15px 0px !important;
        // margin: 0px 50px !important;
      }

      &:hover {
        background-color: $bg-light !important;
      }
      &.active {
        border-bottom: none !important;
      }
    }
  }
}
