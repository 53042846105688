@import "variable.scss";

.mylivechat_template1 {
    bottom: 30px !important;
    right: 30px !important;
    
    @media (max-width: $break-small) {
        // naikin keatas
        right: 0px !important;
        max-width: 100% !important;
    }
}