@import "variable.scss";

#ArticleGridDisplay-root {
  background-color: $bg-grey;

  .link-desc {
    &:hover {
      color: #007BFF;
      cursor: pointer;
    }

    font-size: 14px;
    color: $text-dark-color;
    text-decoration: none;
  }
}
