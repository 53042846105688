@import "variable.scss";

#TextImageSide-root {
  color: $text-dark-color;
  background-color: $bg-light;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;

  padding-left: 5%;
  padding-right: 5%;

  #Produk-Container {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px;
  }
}
