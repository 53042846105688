@import "variable.scss";


#LaporPage-root {
    min-height: 500px;
    background: $bg-grey;
    padding: 0;
    margin: 0;

    #title {
        h1 {
            font-size: 35px;
            font-weight: bold;
            color: $secondary-color;
        }
    }

    #lapor-forms {
        margin: 0;

        iframe {
            width: 100%;
            height: calc(100vh - 75px);
        }
    }
}