@import "variable.scss";

#ArticleNewsDisplay-root {
  scroll-behavior: smooth;
  background-color: $bg-light;

  @media only screen and (max-width: $break-small) {
    margin-top: -70px;
  }
  
  margin-bottom: -50px;

  #tabs {
    // margin-top: -15px;

    .nav-tabs {
      position: relative;
      border-bottom: 2px solid $border-comp-color !important;

      .nav-item {
        &.active {
          color: $text-dark-color;
          background-color: transparent;
          border-color: transparent transparent #f3f3f3;
          border-bottom: 3px solid $primary-color !important;
          font-weight: 700;
        }

        color: #a3a3a3;
        border: 1px solid transparent;
        background-color: transparent;

        .tab-text {
          @media screen and (max-width: $break-small) {
            font-size: 10px;
          }
          @media screen and (min-width: $break-medium) {
            font-size: 15px;
          }
          font-weight: 600;
        }
      }
    }
  }

  #nav-tabContent {
    margin-top: 15px;
  }
}
