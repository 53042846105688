@import "variable.scss";

#FullPageContent-root {
  background-color: $bg-grey;

  #paper {

    margin-top: 1vh;

    @media (min-width: 768px) {
      // naikin keatas
      // margin-top: -37vh !important;
    }
  }

  #full {
    margin: auto;

    img {
      border-radius: 10px;
      max-width: 1000px;
      width: 100%;
      height: auto;
    }
  }
}
