@import "variable.scss";

.HomeContentSide-root {
  color: $text-dark-color;
  background-color: $bg-light;

  margin-top: 40px !important;
  margin-bottom: 40px !important;

  #comp-title {
    h2 {
      font-size: large;
      font-weight: bold;
      // color: $secondary-color;
      color: $text-dark-color;
    }

    .border-bawah {
      background-color: $primary-color;
      height: 4px;
      width: 60px;
      border-radius: 30px;
      margin-top: 12px;
    }
  }

  #image-col {
    padding: 0;
    margin: 0;

    .image-div {
      border-radius: 30px;

      height: 100%;
      padding-top: 56.25%; // ratio 16:9
      min-height: 20rem;
      background-size: cover;
      background-position: center center;

      @media screen and (max-width: $break-small) {
        max-width: 85%;
        min-height: 50px;
        background-repeat: no-repeat;
        margin: auto;
        background-size: contain;
      }
    }
  }

  #text-col {
    margin: auto;

    .side-text-box {
      margin: auto;
      max-width: 700px;

      // padding-left: 5%;
      // padding-right: 5%;
    }
  }
}
