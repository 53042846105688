@import "variable.scss";

.logo {
  .img-logo {
    height: auto;
    width: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto; 
    left: 0; 
    bottom: calc(50% - 60px);
    right: 0;
    opacity: 0;
  }
}

@keyframes logoFade0 {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  36% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logoFade1 {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  36% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logoFade2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logoFade3 {
  0% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logo img:nth-of-type(1) {
  // height: 220px;
  height: 180px;
  // margin-top: 30px;
  animation: logoFade0 30s linear 0s 1 normal none, logoFade1 30s linear 30s infinite normal none;
}
.logo img:nth-of-type(2) {
  // height: 260px;
  height: 180px;
  // margin-top: 10px;
  animation-name: logoFade2;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 0s;
}
.logo img:nth-of-type(3) {
  // height: 240px;
  height: 180px;
  // margin-top: 30px;
  animation-name: logoFade3;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 0s;
}
