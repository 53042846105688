@import "variable.scss";

#NewMasthead-root {
  padding-top: 60px;
  padding-bottom: 30px;
  overflow: hidden;
  background-color: $bg-light;

  @media screen and (max-width: $break-small) {
    padding-top: 0px;
  }

  .row {
    //   width: calc(100vh - 70px);
    background-color: $bg-light;

    .carousel-container {
      background-color: $bg-light;
      overflow: hidden;
      width: 1300px;

      @media screen and (min-width: 1600px) {
        width: 1400px;
      }

      .imgCar {
        border-radius: 20px;
        width: 100%;
        height: 570px;
        object-fit: cover;

        @media screen and (max-width: $break-small) {
          height: auto;
          border-radius: 0px;
        }

        @media screen and (min-height: 1080px) {
          height: 600px;
        }
      }

      .carousel-caption {
        p {
          a {
            .abu {
              background-color: rgba(0, 0, 0, 0.5);
              padding: 5px;
              border-radius: 5px;
            }
            font-size: 20px;
            text-shadow: 0px 0px 10px #000000;
            text-decoration: none;
            color: white;

            &:hover {
              cursor: pointer;
              // color: $primary-color;
              text-decoration: none;
              font-weight: bold;
            }
          }
        }

        h2 {
          text-shadow: 2px 2px 10px #000000;
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}
