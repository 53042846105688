@import "variable.scss";

#Pagination-root {

    li.page-item {
        padding-left: 4px;
        padding-right: 4px;

        &.active {

            a.page-link {
                color: white !important;
                background: $secondary-color !important;
                border: 0;
                border-radius: 4px;
            }
        }

        a.page-link {
            color: $secondary-color !important;
            border-radius: 4px;
        }
    }
}