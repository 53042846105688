html {
  // scroll-behavior: smooth;
  line-height: 1.6;

  body {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
    max-height: 100%;
    overflow-y: auto;
  }
}
