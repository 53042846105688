@import "variable.scss";

#GalleryDisplay-root {
  background: $bg-light;
  // margin-top: -50px;

  div {
    .gallery-thumb {
      border-radius: 5px;

      max-width: 300px;
      margin: 0;
      padding: 0;
      background-color: #202020;
      overflow: hidden;
      position: relative;
      $img-height: 185px;

      .gallery-thumb-inner {
        color: white;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        top: calc(#{$img-height} - 55px - 15px);
        padding: 15px 15px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        background: rgba(32, 32, 32, 0.8);

        .gallery-title {
          overflow: hidden;
          a {
            text-decoration: none;
          }
        }

        h2 {
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          color: $text-light-color;
        }

        p {
          font-size: 13px;
          color: $text-light-color;
          overflow: hidden;
          line-height: 1.2em;
          text-align: justify;
        }
      }

      &:hover {
        .gallery-thumb-inner {
          top: 0;
        }
        img {
          opacity: 0.2;
        }
        h2 {
          text-overflow: unset;
          white-space: normal;

          &:hover {
            cursor: pointer;
            color: deepskyblue;
          }
        }
      }

      img {
        object-fit: cover;
        // border-radius: 5px;
        width: 100%;
        height: $img-height;
      }

      height: $img-height;
    }
  }
}

// image
