@import "variable.scss";

#Pengumuman-root {
  padding: 25px;
  background: $bg-warning;

  overflow: hidden;

  .judul-pengumuman {
    color: rgba(0,0,0,0.7);
    overflow: hidden;
    h1 {
      font-size: 2rem;
    }
  }
  .isi-pengumuman {
    color: rgba(0,0,0,0.9);
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 30px;
    max-width: 1000px;
    display:block;
    margin-left: auto;
    margin-right: auto;

    iframe {
      margin: auto !important;
      display: block;
    }
    .image-style-side {
      float: right;
      width: 50%;
    }
    figcaption {
      margin-top: 15px;
      text-align: center;
    }
    img {
      max-width: 100% !important;
      display:block;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-top: -15px;
    }
    div {
      .ql-align-center {
        text-align: center;
      }
      .ql-align-right {
        text-align: right;
      }
      .ql-align-justify {
        text-align: justify;
      }
    }
  }
}
