@import "variable.scss";

#VideoPopup-root {
  .modal {
    .modal-dialog {
      max-width: 800px;
      margin: 30px auto;

      .modal-content {
        .modal-body {
          position: relative;
          padding: 0px;

          .close {
            position: absolute;
            right: -30px;
            top: 0;
            z-index: 999;
            font-size: 2rem;
            font-weight: normal;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }
}
