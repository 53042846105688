@import "variable.scss";

.linkbox {
  overflow: visible;

  &:hover {
    cursor: pointer;
    color: $primary-color;

    .imgThumb {
      transform: scale(1.1);
    }
  }

  img {
    object-fit: contain;
    max-height: 100px;
    max-width: 80%;
    border-radius: 5px;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: black;

    &:hover {
      cursor: pointer;
      color: $primary-color;
    }
  }
}
