@import "variable.scss";

#paper {
  background-color: $bg-light;
  color: $text-dark-color;
  border-radius: 5px;
  max-width: 1000px;

  #thumbnail {
    img {
      width: 100%;
      height: 100%;
      min-height: 250px;
      max-height: 450px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  #title {
    h1 {
      font-size: x-large;
      font-weight: bold;
      color: $secondary-color;
      text-transform: uppercase;
    }
  }

  #content {
    overflow: hidden;

    iframe {
      margin: auto !important;
      display: block;
    }
    .image-style-side {
      float: right;
      width: 50%;
    }
    figure {
      display: block;
    }
    figcaption {
      margin-top: 15px;
      text-align: center;
    }
    img {
      max-width: 100% !important;
    }
    p {
      margin-bottom: 0.75rem;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 23px;
    }
    h3 {
      font-size: 18px;
    }
  }
}
