@import "variable.scss";

#NewsBox-root {
  border-bottom: 2px solid $border-comp-color;

  #fullArticle {
    .thumbnail {
      width: 100%;
      height: 350px;
      object-fit: cover;
      @media screen and (max-width: $break-medium) {
        height: 250px;
      }
      @media screen and (min-width: $break-medium) {
        height: 350px;
      }
    }
    .title {
      a {
        text-decoration: none;

        h2 {
          font-size: large;
          font-weight: bold;
          color: $text-dark-color;

          &:hover {
            color: $primary-color;
            cursor: pointer;
          }
        }
      }
    }
    .content {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: justify;
      overflow: hidden;
      max-height: 75px;
    }
    #readmore {
      color: $primary-color;
      cursor: default;
    }
  }

  #otherArticle {
    & > a {
      text-decoration: none;
      color: $text-dark-color;
    }
    .articleHighlight {
      padding-right: 10px;
      border-radius: 20px;

      .thumbnail {
        width: 100%;
        height: 80px;
        object-fit: cover;
      }
      .title {
        a {
          text-decoration: none;
          h2 {
            font-size: medium;
            font-weight: bold;
            color: $text-dark-color;

            &:hover {
              color: $primary-color;
              cursor: pointer;
            }
          }
        }
      }
      .timestamp {
        font-size: 14px;
      }
    }
  }
}
