@import "variable.scss";

.galleryBox {
  padding: 15px;
  text-align: left;

  .card {
    position: relative;
    max-width: 550px;
    max-height: 600px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      -ms-transform: scale(1.1, 1.1); /* IE 9 */
      -webkit-transform: scale(1.1, 1.1); /* Safari */
      transform: scale(1.1, 1.1);

      -moz-box-shadow: 5px 5px 10px 10px #ddd;
      -webkit-box-shadow: 5px 5px 10px 10px #ddd;
      box-shadow: 5px 5px 10px 10px #ddd;

      h5 {
        color: $secondary-color;

        a {
          color: $secondary-color;
        }
      }
    }
  }

  .badge-img {
    position: absolute;
    top: -35px;
    left: 13px;
  }

  .card-img-top {
    width: 100%;
    height: auto;
    max-height: 280px;
    object-fit: cover;
    border-radius: 5px;
    border-radius: 5px;
  }

  .body-title {
    min-height: 130px;
    // max-height: 150px;
  }

  .card-body {
    margin-top: -3px;
    position: relative;

    h5 {
      font-size: 16px;
      font-weight: 700;
      color: $text-dark-color;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: $text-dark-color;

        &:hover {
          cursor: pointer;
          color: $primary-color;
        }
      }

      &:hover {
        cursor: pointer;
        color: $primary-color;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .card-footer {
    background: #fff;
  }

  // background-color: $bg-light;
  // padding: 25px;
  // border-radius: 5px;

  // img {
  //   object-fit: cover;
  //   object-position: 50% 50%;
  // }

  // #box {
  //   &:hover {
  //     .title {
  //       // color: $primary-color;
  //     }
  //     .imgThumb {
  //       // transform: scale(1.02);
  //     }
  //   }

  //   width: 100%;
  //   min-width: 150px;
  //   max-width: 320px;

  //   .imgThumb {
  //     width: 100%;
  //     height: 160px;
  //     border-radius: 5px;
  //     object-fit: cover;
  //   }

  //   .title {
  //     &:hover {
  //       cursor: pointer;
  //       color: $primary-color;
  //       a {
  //         cursor: pointer;
  //         color: $primary-color;
  //       }
  //     }

  //     color: $text-dark-color;
  //     font-size: 16px;
  //     margin-top: 10px;
  //     margin-bottom: 4px;
  //     padding-bottom: 8px;
  //     font-weight: bold;

  //     a {
  //       color: $text-dark-color;
  //       font-size: 16px;
  //       text-decoration: none;
  //     }
  //   }

  //   .misc {
  //     border-top: 1px solid $border-comp-color;
  //     margin-top: 8px;
  //     padding-top: 16px;

  //     .col {
  //       padding: 0;
  //       margin: 0;

  //       p {
  //         font-size: 14px;
  //         font-weight: 200;
  //         border-bottom: 0px;
  //       }
  //     }
  //   }
  // }
}
