$primary-color: #0082d4;
$secondary-color: #0b67a0;
$third-color: #2a557f;
$text-dark-color: #4a4a4a;
$text-light-color: #eaeaea;
$border-comp-color: #e8e8e8;
$bg-light: #fafafa;
// $bg-grey: #f2f2f2;
$bg-grey: #f5f5f5;
$bg-gradient: linear-gradient(141deg, #009dff, #0082d4 50%, #262da8);
$bg-gradient2: linear-gradient(141deg, rgba(0, 157, 255, 1), rgba(0, 130, 212, 0.85) 50%, rgba(38, 45, 168, 1));
// $bg-warning: linear-gradient(141deg, rgb(248, 208, 48), rgb(240, 212, 101) 50%, rgb(248, 208, 48));

$bg-warning: linear-gradient(180deg, rgba(164, 196, 236, 0.17) 100%, rgba(255, 255, 30, 0) 2.93%);

$break-small: 576px;
$break-medium: 768px;

$color-success: #5cb85c;
$color-info: #17a2b8;
$color-warning: #ffc107;
