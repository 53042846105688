@import "variable.scss";

#Footer-root {

  width: 100%;
  // background: $primary-color;
  background: $secondary-color;
  color: $text-light-color;

  #main-footer {
    max-width: 1400px;

    #logo-footer {
      div {
        display: block;

        img {
          max-height: 100px;
        }
      }
    }

    #address-footer {
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
      h3 {
        font-size: 18px;
      }
    }

    #link-footer {
      div {
        padding: 10px;

        p {
          font-size: 18px;
          font-weight: bold;
          padding: 5px;
        }

        img {
          height: 30px;
          width: 30px;
          margin-left: 8px;
          margin-right: 8px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  #bottom-footer {
    max-width: 1400px;
    height: 45px;
    background: $secondary-color;
  }
}
