@import "variable.scss";


.Lapor-Card {

    .card-img-top {
        height: 150px;
        width: 100%;
        object-fit: cover;
    }

    .card-title {
        color: $text-dark-color;
        font-weight: bold;
        font-size: 16px;
    }

    .card-body {
        min-height: 150px;
        font-size: 14px;
    }
}