@import "variable.scss";

#RadioApp-root {
  background-color: #f2f5f8;
  overflow: hidden;

  p {
    text-align: justify;
  }

  #gambarApp {
    margin-top: -100px;
  }
}
