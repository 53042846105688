@import "variable.scss";


.masthead {

  overflow: hidden;
  height: calc(100vh - 70px);

  .carouselMast {
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    background: $bg-gradient2;
    padding: 0;
    width: 100%;

    .carousel-item {
      height: calc(100vh - 70px);
      
      width: 100%;
      min-height: 350px;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      .carousel-caption {
        p {
          a {
            .abu {
              background-color: rgba(0, 0, 0, 0.5);
              padding: 5px;
              border-radius: 5px;
            }
            font-size: 20px;
            text-shadow: 0px 0px 10px #000000;;
            text-decoration: none;
            color: white;
          
            &:hover {
              cursor: pointer;
              // color: $primary-color;
              text-decoration: none;
              font-weight: bold;
            }
          }
        }

        h2 {
          text-shadow: 2px 2px 10px #000000;
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  

  // background: $bg-gradient2, url("/images/gedung-big.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  padding-top: 25px;
  font-family: Lato, -apple-system, BlinkMacSystemFont, sans-serif;
  color: $text-light-color;
  border-bottom: 0px solid $border-comp-color;
  min-height: calc(100vh - 70px);
  // width: 100vw;
  // min-height: 350px;
  position: static;

  .front-overlay {
    padding-top: calc(50vh - 120px);
    padding-left: 100px;
    padding-right: 100px;
    // overflow: hidden;

    .teks {
      padding-left: 100px;
      margin-top: -40px;
      @media screen and (max-width: $break-small) {
        margin-top: -80px;
        padding-left: 0px;
      }

      .title {
        h1 {
          font-weight: bold;
          padding-bottom: 10px;
          font-size: 5vw;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
          @media screen and (max-width: $break-small) {
            font-size: 7vw;
          }
          @media screen and (min-width: $break-medium) {
            font-size: 40px;
          }
        }
      }
      .subtitle {
        p {
          // font-size: 27px;
          font-size: 3vw;
          font-weight: medium;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
          @media screen and (max-width: $break-small) {
            font-size: 4vw;
          }
          @media screen and (min-width: $break-medium) {
            font-size: 27px;
          }
        }
      }
    }
    .gambar {
      padding-top: 0px;
    }
  }

  .back-overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: $bg-gradient2;
    padding: 0;

    // iframe {
    //   padding: 0;
    //   margin: 0;
    //   margin-top: 75px;
    //   height: calc(100vh - 75px);
    //   width: calc(100vw - 0px);
    //   border: none;
    //   overflow: hidden;
    //   opacity: 0.4;
    //   @media screen and (min-width: $break-medium) {
    //     width: calc(100vw - 15px);
    //   }
    // }
  }
}

// #infografis {
//   margin-top: 75px;
//   object-fit: cover;
//   height: calc(100vh - 75px);
//   width: calc(100vw - 0px);
// }
