@import "variable.scss";

#HomeContent-root {
  #comp-title {
    .col {
      max-width: 1400px;

      h2 {
        font-size: large;
        font-weight: bold;
        // color: $secondary-color;
        color: $text-dark-color;
      }

      .border-bawah {
        background-color: $primary-color;
        height: 5px;
        width: 60px;
        border-radius: 30px;
        margin-top: 15px;
      }
    }
  }
  .home-content-children {
    .col {
      max-width: 1400px;
    }
  }
}
