@import "variable.scss";


#thumbnail {
    width: 100%;
    max-height: 75vh;
    object-fit: cover;
}

#Profile-page {
    min-height: calc(100vh - 370px);
    background: $bg-grey;
}