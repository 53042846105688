@import "variable.scss";


#ArticlePage-root {
    background: $bg-grey;

    .thumbnail-container {
        
        @media (min-width: 768px) {
           // kalo gak ada thumbnail (jaga2 marging-top - di paper)
            min-height: 40vh;
          }

        #thumbnail {
            width: 100%;
            max-height: calc(100vh - 70px);
            object-fit: cover;
            margin-top: 0px;
        }
    }

    #isso-thread {
        max-width: 950px;
        margin-bottom: 100px;
        padding-left: 25px;
        padding-right: 25px;
        // display: none;

        h4 {
            font-size: 18px;
            margin-bottom: 15px;
        }
        .textarea {
            font-size: 1rem;
            min-height: 100px !important;
        }
        .auth-section {
            padding-top: 5px;

            input {
                // background: $bg-light;
                color: $text-dark-color !important;
            }

            .input-wrapper {
                margin-right: 10px;

                &:nth-child(3) {
                    display: none;
                }
            }

            .post-action {
                margin-left: 10px;

                input {
                    color: $text-dark-color !important;
                    font-size: 14px !important;
                    padding: 6px;
                    background: $bg-light;
                }
            }
        }
    }

    // #button-comment {
    //     max-width: 950px;
    //     margin-bottom: 40px;
    //     padding-left: 25px;
    //     padding-right: 25px;

    //     button:not(:hover) {
    //         border: 0;
    //         color: $text-dark-color;
    //     }
    // }
}