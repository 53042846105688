@import "variable.scss";

#GallerySearchContent-root {
  background-color: $bg-grey;

  #GallerySearchContent-sizing {
    max-width: 1500px;
    margin: auto;

    #GallerySearchContent-article {
      color: $text-dark-color;

      .article-container {
        background-color: $bg-light;
        border-radius: 5px;
        padding: 15px;

        .article-thumb {
          overflow: hidden;
          padding-top: 10px;
          padding-bottom: 10px;

          img {
            border-radius: 5px;
            height: 170px;
            width: 100%;
            object-fit: cover;
          }
        }

        .article-content {
          .article-title {
            width: 100%;
            height: 60px;
            color: $text-dark-color;
            font-size: 20px;
            padding-top: 15px;
            padding-bottom: 15px;
            font-weight: bold;
            border-bottom: 1px solid $border-comp-color;

            a {
              width: 95%;
              height: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-decoration: none;
              color: $text-dark-color;

              &:hover {
                color: $primary-color;
                cursor: pointer;
                text-decoration: none;
              }
            }
          }

          .article-description {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            text-overflow: ellipsis;
            text-align: justify;
            overflow: hidden;
            font-size: 14px;
            line-height: 22px;

            @media screen and (max-width: $break-small) {
              max-height: 80px;
            }
            @media screen and (min-width: $break-medium) {
              height: 80px;
            }
          }

          .article-type-time {
            width: 100%;
            height: 40px;
            margin-top: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 1px solid $border-comp-color;

            .col {
              padding: 0;
              margin: 0;
            }

            p {
              font-size: 14px;
              font-weight: 200;
            }
          }
        }
      }
    }
  }
}
